<template>
  <div
    :class="[
      'wrapper-modal',
      { 'wrapper-modal--success': screen === 'screen-four' }
    ]"
  >
    <div class="wrapper-modal__header">
      <div class="header__title">{{ $t('importWialonTranslater.title') }}</div>
      <div class="header__close">
        <close-icon @click.native="closeWialon" />
      </div>
    </div>

    <div v-if="screen === 'screen-one'" class="modal-screen">
      <div class="wrapper-modal__body">
        <p>{{ $t('importWialonTranslater.fileObject') }}</p>
        <div class="body__file">
          <div class="select-company">
            <label class="select-company-label" for="">
              {{ $t('importWialonTranslater.inCompany') }}
            </label>
            <el-select
              class="adminSelect"
              v-model="selectCompany"
              filterable="filterable"
              :multiple="false"
              collapse-tags="collapse-tags"
            >
              <el-option
                class="text-ellipsis"
                style="max-width: 265px"
                v-for="item in companies"
                :key="item.id"
                :value="item.id"
                :label="item.name || '-'"
              />
            </el-select>
          </div>
          <label class="select-description">
            <input
              type="file"
              accept=".wlp, application/zip, text/xml, application/x-zip, application/x-zip-compressed, application/octet-stream"
              @change="showFile"
            />
            {{ $t('importWialonTranslater.selectFile') }}
          </label>
          <files-icon />
        </div>
        <span class="body__fileName">
          {{ $t('importWialonTranslater.typeFiles') }}
        </span>
        <span v-if="error" class="body__error">{{ error }}</span>
        <span v-else class="body__fileName">{{ fileName }}</span>
      </div>
      <div class="wrapper-modal__footer">
        <skif-button
          :class="[
            'primary wialon-btn',
            {
              'wialon-btn--disabled': !this.file || this.error || !selectCompany
            }
          ]"
          @click="getImport"
        >
          {{ $t('importWialonTranslater.next') }}
        </skif-button>
      </div>
    </div>

    <div v-if="screen === 'screen-two'" class="modal-screen">
      <div class="wrapper-modal__body">
        <p>{{ $t('importWialonTranslater.findEquipment') }}</p>
        <div class="wrapper-devices">
          <div class="list-devices">
            <div
              class="devices"
              v-for="(device, index) in wialonImport.terminals"
              :key="index"
            >
              {{ device.key }}
            </div>
          </div>
          <div class="select-devices">
            <el-select
              v-for="(select, index) in wialonImport.terminals"
              v-model="select.value"
              :key="index"
              :class="isTerminalError ? 'select_error' : ''"
            >
              <el-option
                v-for="terminal in terminalType"
                :key="terminal.key"
                :value="terminal.key"
                :label="terminal.value"
              />
            </el-select>
            <span v-if="isTerminalError" class="select-devices__error">
              {{ $t('importWialonTranslater.selectProtocol') }}
            </span>
          </div>
        </div>
      </div>
      <div v-if="status === 200" class="wrapper-modal__footer">
        <skif-button class="secondary wialon-btn" @click="goScreenOne">
          {{ $t('importWialonTranslater.back') }}
        </skif-button>
        <skif-button class="primary wialon-btn" @click="getAnalyze">
          {{ $t('importWialonTranslater.next') }}
        </skif-button>
      </div>
    </div>

    <div v-if="screen === 'screen-three'" class="modal-screen">
      <div class="wrapper-modal__body">
        <p>{{ $t('importWialonTranslater.findedParametrs') }}</p>

        <!-- <el-collapse v-model="activeNames" @change="handleChange">
          <el-collapse-item
            v-for="(analyze, index) in analyzeWialon.terminals_params"
            :key="index"
            :title="analyze.terminal_type.value"
            :name="index"
          >
            <span class="name-device">
              {{ analyze.terminal_type.value }}
            </span>
            <div
              style="height: 500px"
              v-for="param in analyze.params"
              :key="param.key"
              class="overflow-y-auto name-param"
            >
              <span class="param"> {{ param.key }} </span>
              <el-select v-model="param.value" filterable>
                <el-option
                  v-for="(paramsKey, idx) in params[analyze.terminal_type.key]"
                  :key="idx"
                  :value="paramsKey.value"
                  :label="paramsKey.label"
                />
              </el-select>
            </div>
          </el-collapse-item>
        </el-collapse> -->
        <div
          v-for="(analyze, index) in analyzeWialon.terminals_params"
          :key="index"
          :class="[
            'analyze-device',
            { 'analyze-device--is-open': isOpenAccordeon && count === index }
          ]"
        >
          <div class="accordeon-title" @click="openAccordeon(index)">
            <span
              v-if="isOpenAccordeon && count === index"
              class="accordeon-icon"
            >
              -
            </span>
            <span v-else class="accordeon-icon"> + </span>
            <span class="name-device">
              {{ analyze.terminal_type.value }}
            </span>
          </div>

          <div
            class="name-params"
            id="scrollDiv"
            :class="[
              'name-params',
              'overflow-y-auto',
              { 'name-params--is-open': isOpenAccordeon && count === index }
            ]"
          >
            <div
              v-for="(param, index) in analyze.params"
              :key="index"
              class="name-param"
            >
              <span class="param"> {{ param.key }} </span>
              <el-select ref="select" v-model="param.value" filterable>
                <el-option
                  v-for="(paramsKey, idx) in params[analyze.terminal_type.key]"
                  :key="idx"
                  :value="paramsKey.value"
                  :label="paramsKey.label"
                />
              </el-select>
              <!-- <span
                class="text-notify ml-4"
                v-if="
                  param.value === null ||
                  param.value === 'null' ||
                  param.value === ''
                "
              >
                Выберите параметр</span
              > -->
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper-modal__footer">
        <skif-button class="secondary wialon-btn" @click="goScreenTwo">
          <p>{{ $t('importWialonTranslater.back') }}</p>
        </skif-button>
        <skif-button
          :disabled="!isImportBtn"
          class="primary wialon-btn"
          @click="checkImport"
        >
          <p>{{ $t('importWialonTranslater.import') }}</p>
        </skif-button>
      </div>
    </div>

    <div v-if="screen === 'screen-four'" class="modal-screen">
      <div class="wrapper-modal__body success-screen">
        {{ messageText }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import CloseIcon from '@/components/admin-panel/components/assets/icons/CloseIcon.vue'
import FilesIcon from '@/components/admin-panel/components/assets/icons/FilesIcon.vue'

export default {
  name: 'Import-Wialon',
  components: {
    CloseIcon,
    FilesIcon
  },
  data() {
    return {
      activeNames: [0],
      maxSizeFile: 5242880, // 5mb
      error: '',
      file: null,
      fileName: '',
      screen: 'screen-one',
      isOpenAccordeon: true,
      count: 0,
      messageText: '',
      selectCompany: '',
      currentTerminal: null,
      currentKey: null,
      isTerminalError: false,
      isImportBtn: false
    }
  },
  computed: {
    ...mapGetters({
      companyId: 'login/activeCompanyId',
      wialonImport: 'wialon/wialon',
      terminalType: 'dictionary/terminalType',
      analyzeWialon: 'wialon/analyzeWialon',
      params: 'dictionary/params',
      companies: 'login/companies',
      status: 'wialon/status'
    })
  },
  watch: {
    screen(val) {
      if (val === 'screen-three') {
        setTimeout(() => {
          const div = document.getElementById('scrollDiv')
          if (div) {
            const selects = this.$refs.select
            div.addEventListener('scroll', function () {
              selects.forEach((select) => select.blur())
            })
          }
        }, 2000)
      }
    },
    wialonImport() {
      // this.currentKey = this.wialonImport.terminals[0].key
      this.wialonImport.terminals.forEach((terminal) => (terminal.value = ''))
    },
    currentKey(val) {
      val ? (this.isTerminalError = false) : (this.isTerminalError = true)
    }
  },

  methods: {
    ...mapActions({
      getWialonImport: 'wialon/getImportWialon',
      getAnalyzeWialon: 'wialon/getAnalyze',
      createImport: 'wialon/createImport'
    }),

    ...mapMutations({
      setClearArray: 'wialon/setClearArray'
    }),
    handlScroll(val) {
      console.log('scrolled', val)
    },
    handleChange(val) {
      console.log(val)
    },
    async checkImport() {
      const isError = false
      // this.analyzeWialon.terminals_params.forEach((terminal) => {
      //   terminal.params.forEach((param, index) => {
      //     if (!param.value) {
      //       isError = true
      //     } else {
      //       isError = false
      //     }
      //   })
      // })
      if (isError) {
        console.log(isError)
      } else {
        await this.createImport({
          id: this.analyzeWialon.id,
          terminals: this.analyzeWialon.terminals_params,
          companyId: this.selectCompany
        })
          .then((resp) => {
            this.messageText = resp.data.result
          })
          .catch((err) => {
            console.log(err.message, 'err')
          })
        // this.messageText = data.data.result
        this.screen = 'screen-four'
      }
    },

    openAccordeon(idx) {
      this.count = idx
      this.analyzeWialon.terminals_params.forEach((_el, index) => {
        if (idx === index) {
          return (this.isOpenAccordeon = true)
        }
      })
    },

    goScreenOne() {
      if (this.screen === 'screen-two') {
        this.screen = 'screen-one'
      }
    },

    goScreenTwo() {
      if (this.screen === 'screen-three') {
        this.screen = 'screen-two'
      }
    },

    getAnalyze() {
      const newArr = []
      this.terminalType.forEach((el) => {
        this.wialonImport.terminals.forEach((item) => {
          if (!item.value) {
            this.isTerminalError = true
          } else {
            this.isTerminalError = false
            if (el.key === item.value) {
              newArr.push({ key: item.key, value: el })
            }
          }
        })
      })
      if (!this.isTerminalError) {
        this.getAnalyzeWialon({
          id: this.wialonImport.id,
          terminal: newArr,
          companyId: this.selectCompany
        }).then((res) => {
          if (res.data) {
            this.isImportBtn = true
            this.screen = 'screen-three'
          }
          if (res.response && res.response.data && res.response.data.error) {
            this.$notify.error({
              message: res.response.data.error.message,
              position: 'top-right'
            })
          }
        })
      }
    },

    getImport() {
      this.getWialonImport({
        id: this.selectCompany,
        file: this.file
      }).then((res) => {
        if (res === 500) return
        this.screen = 'screen-two'
      })
    },

    closeWialon() {
      this.$emit('close-wialon')
    },

    showFile(event) {
      this.error = ''
      const [file] = event.target.files
      this.file = file
      this.fileName = ''
      if (this.file) {
        this.fileName = this.file.name
        if (this.file.size > this.maxSizeFile) {
          this.error = 'Ошибка: Файл должен быть менее 5 Мб.'
        }
      }
    }
  },

  beforeDestroy() {
    this.file = null
    this.setClearArray()
  }
}
</script>

<style lang="stylus" scoped>
.select-error
  border  1px solid red
  border-radius 5px
.wrapper-modal
  position absolute
  top 50%
  left 50%
  transform translate(-50%, -50%)
  z-index 10
  width 760px
  height 635px
  background-color #fff
  box-shadow 0px 0px 10px rgba(0, 0, 0, 0.1)

  &--success
    height 292px

  &__header
    display flex
    justify-content space-between
    padding 25px
    font-family Source Sans Pro
    font-size 26px
    line-height 32px
    color #5477A9
    font-weight 700

    .header
      &__close
        cursor pointer

  &__body
    p
      padding-bottom 15px
      margin 0 25px
      font-family Lato
      font-weight 500
      font-size 16px
      line-height 24px
      color #677785
      text-align center
      border-bottom 1px solid #E6EAEE
    .body
      &__file
        padding 0 25px 0 25px
        margin-top 68px

        .select-description
          margin-right 13px
          font-family Lato
          font-weight 700
          text-transform uppercase
          font-size 16px
          line-height 16px
          color #5477A9
          cursor pointer

        input[type="file"]
          display none
      &__error
        padding 16px 25px 0 25px
        font-family Source Sans Pro
        font-size 14px
        color #F56C6C
      &__fileName
        padding 16px 25px 0 25px
        font-family Source Sans Pro
        font-size 14px
        color #5477A9
  &__footer
    position absolute
    bottom 25px
    right 25px
    .wialon-btn
      width 220px
      height 45px
      &--disabled
        opacity 0.4
        pointer-events none
    .secondary
      background none
      color #5477A9
      cursor pointer

.modal-screen
  .wrapper-devices
    display flex
    padding 35px 25px 0 25px
    .list-devices
      .devices
        height 50px
        font-family Source Sans Pro
        font-size 18px
        color #3C5E8F
        font-weight 600
    .select-devices
      display flex
      flex-direction column
      margin-left 26px
      .el-select
        width 350px
        margin-bottom 15px
      span
        font-family Source Sans Pro
        font-size 14px
        color #F56C6C
      .select_error
        border 1px solid #F56C6C

.analyze-device
  padding 35px 25px 0 25px

  &--is-open
    width 95%
    margin 0 auto
    margin-top 14px
    box-shadow 0px 0px 10px rgba(0, 0, 0, 0.1)

  .accordeon-title
    margin-bottom 25px
    cursor pointer

  .name-device, .accordeon-icon
    font-family Source Sans Pro
    font-size 18px
    font-weight 600
    line-height 19px
    color #3C5E8F
    cursor pointer

  .name-params
    height 0
    overflow hidden
    &--is-open
      height 200px
      overflow-y auto

    .name-param
      height 45px
      font-family Source Sans Pro
      font-size 14px
      font-weight 700
      color #5477A9
      .param
        display inline-block
        width 160px

.select-company
  margin-bottom 50px
  .select-company-label
    margin-right 127px
    font-weight 600
    font-size 18px
    line-height 19px
    color #3C5E8F

.el-select.admin-select
  width 349px

.success-screen
  margin 50px 0
  text-align center
  font-family Source Sans Pro
  font-size 16px
  font-weight 700
  line-height 27px
  color #5477A9
</style>
