var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'wrapper-modal',
    { 'wrapper-modal--success': _vm.screen === 'screen-four' }
  ]},[_c('div',{staticClass:"wrapper-modal__header"},[_c('div',{staticClass:"header__title"},[_vm._v(_vm._s(_vm.$t('importWialonTranslater.title')))]),_c('div',{staticClass:"header__close"},[_c('close-icon',{nativeOn:{"click":function($event){return _vm.closeWialon.apply(null, arguments)}}})],1)]),(_vm.screen === 'screen-one')?_c('div',{staticClass:"modal-screen"},[_c('div',{staticClass:"wrapper-modal__body"},[_c('p',[_vm._v(_vm._s(_vm.$t('importWialonTranslater.fileObject')))]),_c('div',{staticClass:"body__file"},[_c('div',{staticClass:"select-company"},[_c('label',{staticClass:"select-company-label",attrs:{"for":""}},[_vm._v(" "+_vm._s(_vm.$t('importWialonTranslater.inCompany'))+" ")]),_c('el-select',{staticClass:"adminSelect",attrs:{"filterable":"filterable","multiple":false,"collapse-tags":"collapse-tags"},model:{value:(_vm.selectCompany),callback:function ($$v) {_vm.selectCompany=$$v},expression:"selectCompany"}},_vm._l((_vm.companies),function(item){return _c('el-option',{key:item.id,staticClass:"text-ellipsis",staticStyle:{"max-width":"265px"},attrs:{"value":item.id,"label":item.name || '-'}})}),1)],1),_c('label',{staticClass:"select-description"},[_c('input',{attrs:{"type":"file","accept":".wlp, application/zip, text/xml, application/x-zip, application/x-zip-compressed, application/octet-stream"},on:{"change":_vm.showFile}}),_vm._v(" "+_vm._s(_vm.$t('importWialonTranslater.selectFile'))+" ")]),_c('files-icon')],1),_c('span',{staticClass:"body__fileName"},[_vm._v(" "+_vm._s(_vm.$t('importWialonTranslater.typeFiles'))+" ")]),(_vm.error)?_c('span',{staticClass:"body__error"},[_vm._v(_vm._s(_vm.error))]):_c('span',{staticClass:"body__fileName"},[_vm._v(_vm._s(_vm.fileName))])]),_c('div',{staticClass:"wrapper-modal__footer"},[_c('skif-button',{class:[
          'primary wialon-btn',
          {
            'wialon-btn--disabled': !this.file || this.error || !_vm.selectCompany
          }
        ],on:{"click":_vm.getImport}},[_vm._v(" "+_vm._s(_vm.$t('importWialonTranslater.next'))+" ")])],1)]):_vm._e(),(_vm.screen === 'screen-two')?_c('div',{staticClass:"modal-screen"},[_c('div',{staticClass:"wrapper-modal__body"},[_c('p',[_vm._v(_vm._s(_vm.$t('importWialonTranslater.findEquipment')))]),_c('div',{staticClass:"wrapper-devices"},[_c('div',{staticClass:"list-devices"},_vm._l((_vm.wialonImport.terminals),function(device,index){return _c('div',{key:index,staticClass:"devices"},[_vm._v(" "+_vm._s(device.key)+" ")])}),0),_c('div',{staticClass:"select-devices"},[_vm._l((_vm.wialonImport.terminals),function(select,index){return _c('el-select',{key:index,class:_vm.isTerminalError ? 'select_error' : '',model:{value:(select.value),callback:function ($$v) {_vm.$set(select, "value", $$v)},expression:"select.value"}},_vm._l((_vm.terminalType),function(terminal){return _c('el-option',{key:terminal.key,attrs:{"value":terminal.key,"label":terminal.value}})}),1)}),(_vm.isTerminalError)?_c('span',{staticClass:"select-devices__error"},[_vm._v(" "+_vm._s(_vm.$t('importWialonTranslater.selectProtocol'))+" ")]):_vm._e()],2)])]),(_vm.status === 200)?_c('div',{staticClass:"wrapper-modal__footer"},[_c('skif-button',{staticClass:"secondary wialon-btn",on:{"click":_vm.goScreenOne}},[_vm._v(" "+_vm._s(_vm.$t('importWialonTranslater.back'))+" ")]),_c('skif-button',{staticClass:"primary wialon-btn",on:{"click":_vm.getAnalyze}},[_vm._v(" "+_vm._s(_vm.$t('importWialonTranslater.next'))+" ")])],1):_vm._e()]):_vm._e(),(_vm.screen === 'screen-three')?_c('div',{staticClass:"modal-screen"},[_c('div',{staticClass:"wrapper-modal__body"},[_c('p',[_vm._v(_vm._s(_vm.$t('importWialonTranslater.findedParametrs')))]),_vm._l((_vm.analyzeWialon.terminals_params),function(analyze,index){return _c('div',{key:index,class:[
          'analyze-device',
          { 'analyze-device--is-open': _vm.isOpenAccordeon && _vm.count === index }
        ]},[_c('div',{staticClass:"accordeon-title",on:{"click":function($event){return _vm.openAccordeon(index)}}},[(_vm.isOpenAccordeon && _vm.count === index)?_c('span',{staticClass:"accordeon-icon"},[_vm._v(" - ")]):_c('span',{staticClass:"accordeon-icon"},[_vm._v(" + ")]),_c('span',{staticClass:"name-device"},[_vm._v(" "+_vm._s(analyze.terminal_type.value)+" ")])]),_c('div',{staticClass:"name-params",class:[
            'name-params',
            'overflow-y-auto',
            { 'name-params--is-open': _vm.isOpenAccordeon && _vm.count === index }
          ],attrs:{"id":"scrollDiv"}},_vm._l((analyze.params),function(param,index){return _c('div',{key:index,staticClass:"name-param"},[_c('span',{staticClass:"param"},[_vm._v(" "+_vm._s(param.key)+" ")]),_c('el-select',{ref:"select",refInFor:true,attrs:{"filterable":""},model:{value:(param.value),callback:function ($$v) {_vm.$set(param, "value", $$v)},expression:"param.value"}},_vm._l((_vm.params[analyze.terminal_type.key]),function(paramsKey,idx){return _c('el-option',{key:idx,attrs:{"value":paramsKey.value,"label":paramsKey.label}})}),1)],1)}),0)])})],2),_c('div',{staticClass:"wrapper-modal__footer"},[_c('skif-button',{staticClass:"secondary wialon-btn",on:{"click":_vm.goScreenTwo}},[_c('p',[_vm._v(_vm._s(_vm.$t('importWialonTranslater.back')))])]),_c('skif-button',{staticClass:"primary wialon-btn",attrs:{"disabled":!_vm.isImportBtn},on:{"click":_vm.checkImport}},[_c('p',[_vm._v(_vm._s(_vm.$t('importWialonTranslater.import')))])])],1)]):_vm._e(),(_vm.screen === 'screen-four')?_c('div',{staticClass:"modal-screen"},[_c('div',{staticClass:"wrapper-modal__body success-screen"},[_vm._v(" "+_vm._s(_vm.messageText)+" ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }